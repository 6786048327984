.content-header {
    background: url(../assets/img/banner-img-1.jpg) top/cover no-repeat;
    padding-top: 104px;
}

.btn:not(.nav-btns button){
    background-color:  #ffffff;
    text-decoration: none;
    color: rgb(85, 85, 85);
    padding: 10, 28px;
    border-radius: 25px;
    border: 1px solid rgb(85, 85, 85);
}

.btn:not(.nav-btns button):hover{
    background-color: var(--pink);
    color: #ffffff;
    border-color: var(--pink);
}

.carousel-inner h1 {
    font-size: 68px;
    font-family: sans-serif;
}
.card_product {
    padding: 10px;
    cursor: pointer;
    transition: .3s all ease-in-out;
    height: auto;
}

.card_product:hover {
    box-shadow: 2px 2px 15px #FF2D00;
    transform: scale(1.02);
}

.collection-img span {
    top: 20px;
    right: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50%;
}

.collection-list  {
    border-radius: 20px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
    padding: 15px;
}

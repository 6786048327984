.search-box {
    width: 800px;
    border-radius: 5px;
    border: 1px solid var(--pink);
    padding: auto;
}

@media (min-width: 427px) {
    .search-box {
        width: 300px;
    }
}
.slider_wrapper {
  padding: 0 1rem;
}
.slider_wrapper .slick-prev:before,
.slider_wrapper .slick-next:before {
  font-size: 30px;
  opacity: 1;
  color: black;
}
.slider_wrapper .slick-prev {
  left: -30px;
}
.slider_wrapper .slick-prev:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f053";
}
.slider_wrapper .slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f054";
}
.az_content.before {
  position: relative;
}
.az_content.before::after {
  content: "";
  width: 0.2px;
  background: white;
  opacity: 0.4;
  position: absolute;
  height: 161px;
  right: 19px;
  top: -34px;
  transform: rotate(47deg);
}
.az_content.before::before {
  content: "";
  width: 0.2px;
  background: white;
  opacity: 0.4;
  position: absolute;
  height: 161px;
  left: -28px;
  top: -34px;
  transform: rotate(47deg);
}
.shopHeader .dropdown-toggle {
  border-radius: 12px !important;
  font-size: 14px;
}
.shopHeader .dropdown-toggle:hover {
  background-color: #fff !important;
}
.shopHeader .ah_banner {
  background-color: rgb(35, 87, 137);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.shopHeader .space_top {
  margin-top: 4.8rem;
}
.menu_toggle .toggle_btn .btnOpen {
  border: 0;
  background-color: transparent;
  padding: 5px;
}
.menu_toggle .toggle_btn .btnOpen span {
  width: 30px;
  height: 4px;
  display: flex;
  background: #235789;
  margin: 0px 0px 2px;
  border-radius: 3px;
}
.text_info {
  color: #235789 !important;
}
.border_info {
  border-color: #235789 !important;
}
.bg_info {
  background-color: #235789 !important;
}
#dropbtn4:hover {
  background-color: #235789 !important;
}
.fontSize_1 {
  font-size: clamp(12px, 1.5vw, 2rem);
}
.subMenu .subMenu_wrapper {
  background-color: #fe9a008f;
}
.subMenu .subMenu_wrapper .menu_link {
  background-color: transparent;
  border: 0;
  padding: 8px 12px;
  color: #235789;
  font-size: 16px;
}
.subMenu .subMenu_wrapper .menu_link.active {
  background-color: #ffe101;
  border-radius: 0 !important;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.subMenu .subMenu_wrapper .menu_link:hover {
  background-color: inherit !important;
}
.bg_greenv1 {
  background-color: #00ab00;
}
.text_darkv1 {
  color: #000;
}
.timer_wrapper {
  max-width: 210px;
}
.toggle_btn .btnClose {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}
@media (max-width: 1200px) {
  .shopHeader .space_top {
    margin-top: 4.3rem;
  }
}
@media (max-width: 991px) {
  .shopHeader .space_top {
    margin-top: 4.5rem;
  }
  .shopHeader .ah_menu.show {
    margin-left: 0;
    margin-top: 0 !important;
  }
  .shopHeader .ah_menu {
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 999;
    width: 100%;
    margin-left: -100%;
    transition: 0.35s all ease-in-out;
    background: rgba(35, 87, 137, 0.9);
  }
  .shopHeader .ah_menu .bg_greenv1 {
    background-color: transparent;
    color: #fff !important;
  }
  .shopHeader .ah_menu .text_darkv1 {
    color: #fff;
  }
  .timer_wrapper {
    max-width: 180px;
  }
}

@media (max-width: 767px) {
  .slider_wrapper .slick-prev:before,
  .slider_wrapper .slick-next:before {
    font-size: 20px;
  }
  .slider_wrapper .slick-prev {
    left: -25px;
  }
  .shopHeader .space_top {
    margin-top: 4.3rem;
  }
  .timer_wrapper {
    max-width: 150px;
  }
  footer .foo_col1,
  footer .foo_col2,
  footer .foo_col2 {
    border-right: 0px !important;
    border-bottom: 1px solid #dee2e6;
  }
}

@media (max-width: 500px) {
  .timer_wrapper {
    max-width: 120px;
  }
  .shopHeader .space_top {
    margin-top: 4.8rem;
  }
}

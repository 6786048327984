
:root {
  --pink: #d11d44;
}

body {
  font-family: sans-serif;
  background-color: #f8f1f3d0;
}

main {
  min-height: 80vh;
}

h1 {
  font-size: 1.2rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1rem;
  padding: 0.5rem 0;
}


h3 {
  padding: 1rem 0;
}

.rating span {
  margin: 0.1rem;
}
.navbar {
    background-color: azure;
    color: var(--pink) !important;
    box-shadow: 0 3px 9px 3px rgba(0, 0, 0, 0.1);
}
.bg_primary {
   color: var(--pink) !important;
}

.navbar-brand img{
    width: 30px;
}

.dropdown-menu {
 background-color: aliceblue;
}

.dropdown-item {
 border-radius: 2px;
}

@media (min-width: 992px) {
    .nav-items {
        border-bottom: none;
    }
}